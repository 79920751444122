@import '~antd/dist/antd.less';

@layout-header-background: #fff;

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-header {
  padding-left: 16px;
  box-shadow: @shadow-1-down;
 
  span {
    padding: 8px;
  }

  span:hover {
    svg {
      fill: #1890ff;
    }
  }
}

.ant-layout-sider {
  box-shadow: @shadow-1-right;

  .sider__space > * {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .flex-center > * {
    height: 100%;
  }
}

// react-quill
.ql-editor {
  min-height: 300px;
}
